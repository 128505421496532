<template>
    <el-scrollbar class="main-wrapper">
        <el-breadcrumb separator=">" class="genera-breadcrumb" style="margin-left: 20px">
            <el-breadcrumb-item>商品</el-breadcrumb-item>
            <el-breadcrumb-item>添加商品</el-breadcrumb-item>
            <el-breadcrumb-item>发布商品</el-breadcrumb-item>
        </el-breadcrumb>
        <el-form :model="goodDetail" :rules="formRules" ref="goodDetail" label-width="100px" class="demo-ruleForm">
            <div class="first-title">
                <span>填写商品信息</span>
            </div>
            <div class="second-title">商品基本信息</div>
            <el-form-item label="商品标题" prop="good_title">
                <el-input v-model.trim="goodDetail.good_title" maxlength="200" show-word-limit style="width: 908px"></el-input>
            </el-form-item>
            <div class="flex">
                <el-form-item label="销售价(元)" prop="sales_price">
                    <el-input-number class="number-input" v-model="goodDetail.sales_price"
                                     :precision="2" :controls="false" :min="0.01" :max="99999999.99" style="width: 350px"></el-input-number>
                </el-form-item>
                <el-form-item label="市场价(元)" prop="market_price" style="margin-left: 108px">
                    <el-input-number class="number-input" v-model="goodDetail.market_price"
                                     :precision="2" :controls="false" :min="goodDetail.sales_price ? goodDetail.sales_price : 0.01" :max="99999999.99" style="width: 350px"></el-input-number>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="库存" prop="stock">
                    <el-input-number class="number-input" v-model="goodDetail.stock"
                                     :precision="0" :controls="false" :min="0" style="width: 350px"></el-input-number>
                </el-form-item>
                <el-form-item label="商品货号" prop="good_no" style="margin-left: 108px">
                    <el-input v-model.trim="goodDetail.good_no" style="width: 350px"></el-input>
                </el-form-item>
            </div>

            <div class="first-title">
                <span>商品基本属性123</span>
                <el-button type="primary" @click="addPropertyBtn">添加商品基本信息</el-button>
            </div>
            <el-form-item prop="good_property" label-width="0" class="table-form-item">
                <el-table :data="goodDetail.good_property" class="customTable" style="width: 100%; margin-top: 20px;">
                    <el-table-column label="属性" min-width="450px">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.name" style="width: 400px" placeholder="请输入属性"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column label="内容" min-width="420px">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.content" style="width: 400px" placeholder="请输入内容"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-link type="danger" :underline="false" @click="delPropertyBtn(scope.row, scope.$index)">删除</el-link>
                        </template>
                    </el-table-column>
                </el-table>
            </el-form-item>

            <div class="first-title">
                <span>图片</span>
            </div>
            <el-form-item label="商品图片" prop="good_images" class="goods-images-box column-layout">
                <div class="flex">
                    <div class="good-image-item" v-for="imageIndex in 8">
                        <i v-if="goodDetail.good_images[imageIndex - 1]"
                           @click="clearImg(imageIndex)"
                           class="el-icon-error clear-quickTalk-img"></i>
                        <el-upload class="img-uploader"
                                   :action="uploadCover"
                                   :headers="headersParams"
                                   name="file"
                                   accept=".jpg, .jpeg, .png"
                                   :show-file-list="false"
                                   :on-success="function(res, file) {return handleCoverSuccess(res, file, imageIndex)}"
                                   :before-upload="beforeCoverUpload">
                            <img v-if="goodDetail.good_images[imageIndex - 1]" :src="goodDetail.good_images[imageIndex - 1]" class="quickTalk-img">
                            <div class="add-box" v-else>
                                <i class="iconfont add-icon">&#xe817;</i>
                                <div class="add-text">{{imageIndex === 1 ? '主图' : '细节图'}}</div>
                            </div>
                        </el-upload>
                    </div>
                </div>
                <div style="color: #999; margin-top: 12px;">图片至少上传5张，尺寸为800px*800px，仅支持jpg、jpeg、png格式</div>
            </el-form-item>

            <div class="first-title">
                <span>商品描述</span>
            </div>
            <el-form-item label="电脑版商品详情" prop="good_details" class="column-layout" style="margin-top: 30px">
                <vue-tinymce ref="tiny_item" :setup="setup" :setting="setting" v-model="goodDetail.good_details"></vue-tinymce>
            </el-form-item>
            <el-form-item style="text-align: center">
                <el-button type="primary" @click="submitForm('goodDetail')">开始销售</el-button>
            </el-form-item>
        </el-form>
    </el-scrollbar>
</template>

<script>
    import { adminGoodUploadImage, adminGoodUploadImage1, adminGoodSaveGood, adminGoodGetGood } from '@/utils/apis.js'
    export default {
        data() {
            let checkImgLength = (rule, value, callback) => {
                if (value.length === 0) {
                    return callback(new Error('请选择图片'));
                }
                let arrTmp = value.filter(item => {
                    return item
                })
                if (arrTmp.length < 5) {
                    return callback(new Error('商品图片不得少于5张'));
                } else {
                    callback()
                }
            }
            return {
                goodDetail: {
                    id: Number(this.$route.query.id) || null,
                    category_id: Number(this.$route.query.category_id) || null,
                    good_title: '',
                    sales_price: void 0,
                    market_price: void 0,
                    stock: void 0,
                    good_no: '',
                    good_property: [],
                    good_images: [],
                    good_details: '',
                },
                formRules: {
                    good_title: [
                        { required: true, message: '请输入商品标题', trigger: 'blur' },
                        { min: 2, max: 200, message: '长度在 2 到 200 个字符', trigger: 'blur' }
                    ],
                    sales_price: { required: true, message: '请输入销售价', trigger: 'blur' },
                    market_price: { required: true, message: '请输入市场价', trigger: 'blur' },
                    stock: { required: true, message: '请输入库存', trigger: 'blur' },
                    good_no: [
                        { required: true, message: '请输入商品货号', trigger: 'blur' },
                        { min: 6, max: 20, message: '商品货号长度在6-20个字符', trigger: 'blur'}
                    ],
                    good_property: { required: true, message: '请添加商品属性', trigger: 'blur' },
                    good_images: { validator: checkImgLength },
                    good_details: { required: true, message: '请输入电脑版商品详情', trigger: 'blur' },
                },
                headersParams: {
                    Authorization: localStorage.getItem('adminToken')
                },
                uploadCover: adminGoodUploadImage1(),
                setting: {
                    menubar: false,
                    icons: 'custom',
                    plugins: "lists advlist emoticons fullscreen table insertdatetime wordcount customImage",
                    toolbar: [
                        'undo redo | styleselect | bold italic underline strikethrough|fontselect fontsizeselect formatselect|alignleft aligncenter alignright|bullist numlist|',
                        'customImage emoticons forecolor backcolor insertdatetime wordcount|table tabledelete|fullscreen|'
                    ],
                    language: 'zh_CN', //本地化设置
                    height: 800,
                },
                hosturl: localStorage.getItem('hosturl')
            }
        },
        watch: {
            'goodDetail.sales_price'(val) {
                if (this.goodDetail.market_price < val) {
                    this.goodDetail.market_price = val
                }
            }
        },
        mounted() {
            if (this.goodDetail.id) {
                this.getInfo()
            }
        },
        methods: {
            getInfo() {
                let params = {
                    id: this.goodDetail.id
                }
                adminGoodGetGood(params).then((res) => {
                    this.goodDetail = {
                        id: Number(this.$route.query.id) || null,
                        category_id: Number(this.$route.query.category_id) || null,
                        good_title: res.data.good_title,
                        sales_price: res.data.sales_price,
                        market_price: res.data.market_price,
                        stock: res.data.stock,
                        good_no: res.data.good_no,
                        good_property: res.data.good_property,
                        good_images: res.data.good_images,
                        good_details: res.data.good_details,
                    }
                })
            },
            setup(editor) {
                let that = this
                // 自定义上传图片，多图上传
                tinymce.PluginManager.add('customImage', function addPlugin(editor) {
                    editor.ui.registry.addButton('customImage', {
                        icon: 'customImage',
                        onAction: () => {
                            let imageInput = document.createElement('input')
                            imageInput.setAttribute('type', 'file')
                            imageInput.setAttribute('multiple', 'multiple')
                            imageInput.setAttribute('accept', 'image/*')
                            document.body.appendChild(imageInput)
                            // 模拟input点击事件
                            let evt = new MouseEvent("click", {
                                bubbles: false,
                                cancelable: true,
                                view: window
                            });
                            imageInput.dispatchEvent(evt);
                            imageInput.addEventListener('change', e => {
                                // 判断图片大小
                                for (const key in evt.target.files) {
                                    let item = evt.target.files[key]
                                    if (item.size) {
                                        if (item.size / 1024 / 1024 > 10) {
                                            that.$message.warning('图片上传大小应低于10M')
                                            return false
                                        }
                                    }
                                }
                                let arrTmp = []
                                for (const key in evt.target.files) {
                                    let item = evt.target.files[key]
                                    if (item.lastModified) {
                                        arrTmp.push(item)
                                    }
                                }
                                // 插入
                                let formData = new FormData()
                                if (arrTmp.length > 1) {
                                    arrTmp.forEach((item, index) => {
                                        formData.append(`file[${index}]`, item)
                                    })
                                } else {
                                    formData.append('file', evt.target.files[0])
                                }
                                adminGoodUploadImage(formData).then((res) => {
                                    if (res.data.length > 0) {
                                        res.data.forEach(item => {
                                            editor.execCommand('mceInsertContent', false, `<img src='${that.hosturl}${item}'/>`)
                                        })
                                    }
                                    that.$message.success(res.msg)
                                })
                            })
                            // 图片插入完后，remove
                            imageInput.remove()
                        }
                    });
                })
            },
            // 添加商品属性
            addPropertyBtn() {
                let tmp = {
                    name: '',
                    content: ''
                }
                this.goodDetail.good_property.push(tmp)
            },
            // 删除商品属性
            delPropertyBtn(row, index) {
                this.goodDetail.good_property.splice(index, 1)
            },
            // 图片上传前
            beforeCoverUpload(file) {
                // const is10M = file.size / 1024 / 1024 < 10;
                // if (!is10M) {
                //     this.$message.warning('图片尺寸限制为800px x 800px，大小不可超过10MB');
                //     return false;
                // } else {
                    const isSize = new Promise((resolve, reject) => {
                        const width = 800;
                        const height = 800;
                        const _URL = window.URL || window.webkitURL;
                        const img = new Image();
                        img.onload = () => {
                            const valid = img.width === width && img.height === height;
                            valid ? resolve() : reject();
                        };
                        img.src = _URL.createObjectURL(file);
                    }).then(() => {
                        return file;
                        }, () => {
                            this.$message.warning('图片尺寸限制为800px x 800px，大小不可超过10MB');
                            return Promise.reject();
                        },
                    );
                    return isSize;
                // }
            },
            // 图片上传成功后
            handleCoverSuccess(res, file, index) {
                if (res.code === 200) {
                    this.$set(this.goodDetail.good_images, index - 1, res.data[0])
                    this.$message.success(res.msg)
                } else {
                    this.$message.error('图片上传失败，请稍后再试~')
                }
            },
            // 删除图片
            clearImg(index) {
                this.goodDetail.good_images.splice(index - 1, 1, '')
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        for (let i = 0; i < this.goodDetail.good_property.length; i++) {
                            let item = this.goodDetail.good_property[i]
                            if (item.name === '') {
                                return this.$message.warning('商品属性不能为空')
                            }
                            if (item.content === '') {
                                return this.$message.warning('商品内容不能为空')
                            }
                        }

                        // 将没有选择的图片清空
                        this.goodDetail.good_images = this.goodDetail.good_images.filter(item => {
                            return item
                        })

                        let formData = new FormData()
                        formData.append('category_id', this.goodDetail.category_id)
                        formData.append('good_title', this.goodDetail.good_title)
                        formData.append('sales_price', this.goodDetail.sales_price)
                        formData.append('market_price', this.goodDetail.market_price)
                        formData.append('stock', this.goodDetail.stock)
                        formData.append('good_no', this.goodDetail.good_no)
                        formData.append('good_property', JSON.stringify(this.goodDetail.good_property))
                        formData.append('good_images', JSON.stringify(this.goodDetail.good_images))
                        formData.append('good_details', this.goodDetail.good_details)
                        if (this.goodDetail.id) {
                            formData.append('id', this.goodDetail.id)
                        }
                        adminGoodSaveGood(formData).then((res) => {
                            this.$message.success(res.msg)
                            this.$router.push('/practice/goods')
                        }).catch((err) => {})
                    } else {
                        return false;
                    }
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .main-wrapper {
        height: 100%;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .demo-ruleForm {
        padding: 0 20px 20px;
        ::v-deep {
            .el-form-item__label {
                color: #333;
                font-size: 16px;
            }
        }
    }
    .first-title {
        font-size: 20px;
        padding-left: 15px;
        line-height: 1;
        margin-top: 20px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 5px;
            height: 20px;
            background: #2DC079;
            border-radius: 3px;
            transform: translateY(-50%);
        }
    }
    .second-title {
        margin-top: 30px;
        font-size: 16px;
        margin-bottom: 20px;
    }
    .table-form-item {
        ::v-deep .el-form-item__content {
            line-height: inherit;
        }
    }
    .goods-images-box {
        background: #F6F6F6;
        margin-top: 14px;
        padding: 20px 15px;
    }
    .column-layout {
        display: flex;
        flex-direction: column;
        ::v-deep {
            .el-form-item__label {
                text-align: left;
                line-height: 1;
                margin-bottom: 20px;
                width: auto!important;
            }
            .el-form-item__content {
                margin-left: 0!important;
            }
        }
    }
    .good-image-item + .good-image-item {
        margin-left: 10px;
    }
    .good-image-item {
        width: 100px;
        height: 100px;
        position: relative;
        background: #fff;
        .clear-quickTalk-img {
            background: #fff;
            border-radius: 50%;
            position: absolute;
            top: -9px;
            right: -9px;
            z-index: 1;
            font-size: 18px;
            color: #aaa;
            cursor: pointer;
            transition: all .3s;
            &:hover {
                color: #ff0000;
            }
        }
        .img-uploader {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            .quickTalk-img {
                display: block;
                max-width: 100%;
                max-height: 100%;
            }
            .add-box {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                line-height: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .add-icon {
                    color: #BFBFBF;
                    font-size: 24px;
                }
                .add-text {
                    color: #999;
                    font-size: 14px;
                    margin-top: 8px;
                }
            }
        }
    }
    .number-input {
        ::v-deep .el-input__inner {
            text-align: left;
        }
    }

</style>